import React from 'react';
import { Row, Col, Menu, Popover } from 'antd';
import { Link } from 'react-router-dom';
import { PATIENT_PORTAL_LINK } from '../data/Constants';

import CompanyLogo from '../images/company-logo.gif';
import './Navigation.scss';

// Desktop-only navigation bar
const DesktopNavigation = () => (
    <div className='nav-items'>
        <Link to='/'>
            <span className='nav-item'>Home</span>
        </Link>
        <Link to='/team'>
            <span className='nav-item'>Meet the Team</span>
        </Link>
        <Link to='/articles'>
            <span className='nav-item'>Articles</span>
        </Link>
        <Link to='/benefits'>
            <span className='nav-item'>Member Benefits</span>
        </Link>
        <Link to='/location'>
            <span className='nav-item'>Location</span>
        </Link>
        <a href={PATIENT_PORTAL_LINK}><span className='nav-item'>Patient Portal</span></a>
    </div>
);

// Inner contents of the mobile menu
const MobileMenu = ({ onToggle }) => (
    <Menu selectedKeys={[]}>
        <Menu.Item
            className='mobile-nav-link'
            key='/'
            onClick={onToggle}
        >
            <Link to='/'>Home</Link>
        </Menu.Item>
        <Menu.Item
            className='mobile-nav-link'
            key='/team'
            onClick={onToggle}
        >
            <Link to='/team'>Meet the Team</Link>
        </Menu.Item>
        <Menu.Item
            className='mobile-nav-link'
            key='/articles'
            onClick={onToggle}
        >
            <Link to='/articles'>Articles</Link>
        </Menu.Item>
        <Menu.Item
            className='mobile-nav-link'
            key='/benefits'
            onClick={onToggle}
        >
            <Link to='/benefits'>Member Benefits</Link>
        </Menu.Item>
        <Menu.Item
            className='mobile-nav-link'
            key='/location'
            onClick={onToggle}
        >
            <Link to='/location'>Location</Link>
        </Menu.Item>
        <Menu.Item className='mobile-nav-link'>
            <a href={PATIENT_PORTAL_LINK}>Patient Portal</a>
        </Menu.Item>
    </Menu>
);

// Mobile-only navigation menu
const MobileNavigation = ({ visible, onToggle }) => (
    <div className='hamburger-menu-container'>
        <Popover
            content={<MobileMenu onToggle={onToggle} />}
            visible={visible}
            placement='topRight'
        >
            <div
                className={'hamburger-container ' + (visible ? 'change' : '')}
                onClick={onToggle}
            >
                <div className='ham-bar1' />
                <div className='ham-bar2' />
                <div className='ham-bar3' />
            </div>
        </Popover>
    </div>
)

// Full navigation that changes depending on device size
class Navigation extends React.Component {
    state = { visible: false };

    onToggle = () => {
        this.setState(prevState => ({ visible: !prevState.visible }));
    };

    render() {
        const { visible } = this.state;
        return (
            <React.Fragment>
                <div className='nav-wrapper'>
                    <Row>
                        <Col span={6}>
                            <Link to='/'>
                                <img
                                    className='company-logo'
                                    src={CompanyLogo}
                                    alt='Company Logo'
                                />
                            </Link>
                        </Col>
                        <Col span={18}>
                            <div className='mobile-nav'>
                                <MobileNavigation visible={visible} onToggle={this.onToggle} />
                            </div>
                            <div className='desktop-nav'>
                                <DesktopNavigation />
                            </div>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Navigation;