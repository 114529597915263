import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect
} from 'react-router-dom';

import Navigation from "./components/Navigation";
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import TeamPage from './components/TeamPage';
import ArticlePage from "./components/ArticlePage";
import BenefitsPage from "./components/BenefitsPage";
import LocationPage from './components/LocationPage';

import './App.scss';
import ArticleDetailsPage from "./components/ArticleDetailsPage";

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}
const ScrollToTopComponent = withRouter(ScrollToTop);

function App() {
    return (
        <Router>
            <ScrollToTopComponent>
                <Navigation />
                <Switch>
                    {/* Redirect old URLs */}
                    <Route path='/location.php'>
                        <Redirect to='/location' />
                    </Route>
                    <Route path='/member.php'>
                        <Redirect to='/benefits' />
                    </Route>
                    <Route path='/about.php'>
                        <Redirect to='/team' />
                    </Route>
                    {/* !Redirect old URLs */}
                    <Route path='/team'>
                        <TeamPage />
                    </Route>
                    <Route path='/articles/:id'>
                        <ArticleDetailsPage />
                    </Route>
                    <Route path='/articles'>
                        <ArticlePage />
                    </Route>
                    <Route path='/benefits'>
                        <BenefitsPage />
                    </Route>
                    <Route path='/location'>
                        <LocationPage />
                    </Route>
                    <Route path='/'>
                        <HomePage />
                    </Route>
                </Switch>
                <Footer />
            </ScrollToTopComponent>
        </Router>
    );
}

export default App;
