import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';

import BackgroundTitle from "./BackgroundTitle";
import QuestionAnswerEntry from "./QuestionAnswerEntry";
import { Image, Section, SectionTitle, SectionBody, CTAButton } from './common/Section';
import { Centered } from "./common/Layout";
import {
    HomePage_IntroDescription1,
    HomePage_IntroDescription2,
    HomePage_FAQ1_Answer,
    HomePage_FAQ1_Question,
    HomePage_FAQ1_Resource,
    HomePage_FAQ2_Answer,
    HomePage_FAQ2_Question,
    HomePage_FAQ2_Resource,
    HomePage_FAQ3_Answer,
    HomePage_FAQ3_Question,
} from "../data/Copy";

import KayakingImage from '../images/kayaking.jpg';
import Headshot from '../images/headshot-frontpage.jpg';

import './HomePage.scss';

const HomePage = () => (
    <React.Fragment>
        <BackgroundTitle
          imgSrc={KayakingImage}
          title='Wellness.'
          subtitle='It starts with you.'
        />
        <Section>
            <SectionTitle>Cristina Sciavolino-Day, M.D.</SectionTitle>
            <Divider />
            <Row>
                <Col xs={{span: 18, offset: 3}} lg={{span: 8, offset: 0}}>
                    <Image imgSrc={Headshot} alt='Cristina Sciavolino-Day' />
                </Col>
                <Col xs={{span: 24, offset: 0}} lg={{span: 14, offset: 2}}>
                    <div className='overview-text-wrapper'>
                        <SectionBody>{HomePage_IntroDescription1}</SectionBody>
                        <SectionBody>{HomePage_IntroDescription2}</SectionBody>
                        <Centered>
                            <Link to='/articles'>
                                <CTAButton>Read Article</CTAButton>
                            </Link>
                        </Centered>
                    </div>
                </Col>
            </Row>
        </Section>
        <Section>
            <SectionTitle>Frequently Asked Questions</SectionTitle>
            <Divider />
            <QuestionAnswerEntry
              question={HomePage_FAQ1_Question}
              answer={HomePage_FAQ1_Answer}
              resource={HomePage_FAQ1_Resource}
            />
            <QuestionAnswerEntry
              question={HomePage_FAQ2_Question}
              answer={HomePage_FAQ2_Answer}
              resource={HomePage_FAQ2_Resource}
            />
            <QuestionAnswerEntry
              question={HomePage_FAQ3_Question}
              answer={HomePage_FAQ3_Answer}
            />
        </Section>
    </React.Fragment>
);

export default HomePage;