import React from 'react';
import { Col, Divider, Row } from 'antd';
import ArticleCard from "./ArticleCard";
import { Section, SectionTitle } from "./common/Section";
import { ArticleMetadata, ArticlesList, HighlightedArticles } from "../data/Articles";
import './ArticlePage.scss';

const ArticlePage = () => (
    <React.Fragment>
        <Section>
            <div className='highlight-section-wrapper'>
                <SectionTitle>Highlights</SectionTitle>
                <Section>
                    <Row justify='space-around'>
                        {HighlightedArticles.map(articleId => (
                            <Col
                                key={articleId}
                                lg={{span: 9}}
                                xs={{span: 20}}
                            >
                                <ArticleCard
                                    date={ArticleMetadata[articleId].date}
                                    highlighted
                                    publication={ArticleMetadata[articleId].publication}
                                    title={ArticleMetadata[articleId].title}
                                    docHref={ArticleMetadata[articleId].docHref}
                                    id={articleId}
                                />
                            </Col>
                        ))}
                    </Row>
                </Section>
            </div>
        </Section>
        <Section>
            <SectionTitle>Articles</SectionTitle>
            <Divider />
            <Row gutter={[16, 16]}>
                {ArticlesList.map(articleId => (
                    <Col
                        key={articleId}
                        lg={{span: 8}}
                        sm={{span: 12}}
                        xs={{span: 24}}
                    >
                        <ArticleCard
                            date={ArticleMetadata[articleId].date}
                            publication={ArticleMetadata[articleId].publication}
                            title={ArticleMetadata[articleId].title}
                            id={articleId}
                        />
                    </Col>
                ))}
            </Row>
        </Section>
    </React.Fragment>
);

export default ArticlePage;