import React from 'react';
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import {GOOGLE_MAPS_API_KEY, OFFICE_LAT_LNG} from "../data/Constants";

class GoogleMap extends React.Component {
    render() {
        return (
            <Map
                google={this.props.google}
                initialCenter={OFFICE_LAT_LNG}
                zoom={17}
            >
                <Marker
                    title={"Dr. Sciavolino-Day's Office"}
                    position={OFFICE_LAT_LNG}
                />
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAPS_API_KEY
})(GoogleMap);