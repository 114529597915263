import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { InlineLink } from "./common/Section";
import { Centered } from "./common/Layout";
import {
    EMAIL,
    FACEBOOK,
    FAX,
    LINKEDIN,
    MAILTO_LINK,
    PHONE,
} from "../data/Constants";
import './Footer.scss';

import LinkedInImg from '../images/linkedin-icon.svg';
import FacebookImg from '../images/facebook-icon.svg';
import EmailImg from '../images/gmail-icon.svg';

const MediaIcon = ({ title, iconImg, href }) => (
    <Tooltip title={title}>
        <a className='media-icon' href={href}>
            <img className='media-icon-img' src={iconImg} alt={title} />
        </a>
    </Tooltip>
);

const FooterText = ({ title, children }) => (
    <p className='footer-text'>
        <b>{title}: </b>{children}
    </p>
);

const Footer = () => (
    <Centered>
        <div className='footer-wrapper'>
            <Row>
                <Col xs={{span: 24, offset: 0}} lg={{span: 6, offset: 3}}>
                    <MediaIcon title='LinkedIn' iconImg={LinkedInImg} href={LINKEDIN} />
                    <MediaIcon title='Facebook' iconImg={FacebookImg} href={FACEBOOK} />
                    <MediaIcon title='Email' iconImg={EmailImg} href={MAILTO_LINK} />
                </Col>
                <Col xs={{span: 24, offset: 0}} lg={{span: 6, offset: 6}}>
                    <div className='contact-information'>
                        <FooterText title='Phone'>{PHONE}</FooterText>
                        <FooterText title='Fax'>{FAX}</FooterText>
                        <FooterText title='Email'>
                            <InlineLink href={MAILTO_LINK}>{EMAIL}</InlineLink>
                        </FooterText>
                    </div>
                </Col>
            </Row>
            <div>
                Copyright &copy; Cristina Sciavolino-Day, MD, LLC &bull;
                All rights reserved &bull;
                Website by Chris Sciavolino
            </div>
        </div>
    </Centered>
);

export default Footer;
