import { ArticleMetadata, Articles } from "./Articles";

const ArticleText =  {
    [Articles.THE_CONCIERGE_REVOLUTION_PRIMARY_CARE_PERSPECTIVE]: {
        ...ArticleMetadata[Articles.THE_CONCIERGE_REVOLUTION_PRIMARY_CARE_PERSPECTIVE],
        text: [
            "As a daughter of two physicians, I was exposed to medicine at an early age. I would listen to my parents  on  late  night  phone  calls  with  the  hospitals  and patients,  and  our  dinner  time conversations often focused on their day’s work. As I watched my father work 7 days a week it taught  me  the  passion,  sacrifice  and  compassion  needed  for  the  profession  of  medicine. Choosing to become a physician was an easy decision for me.  My parents laid the foundation and  I  had  the  same  desire  to  help  others  to  the  best  of  my  abilities  using  the  best  medical therapies available.  What I was not prepared for nor encouraged to observe were the changes in the medical field that have taken place over the last 20 years that I have practiced.",
            "I  have  always  worked  in  one  form  or  another  in  a  traditional  medical  practice  which  basically consists  of  a  physician  accepting  insurance  plans  as  a  method  of  payment. As  it  has  been handled for years, after I have seen a patient, our office would file the necessary paperwork with the patient’s insurance directly. We would then get paid the amount set forth by the insurance company for the procedure or level of visit charged.  This differs from a concierge model where no insurance is accepted. Instead, an annual membership is charged which usually includes all office  visits,  hospital  visits,  emergency  home  care  and  office  procedures  for  that  year. It  is important to note that concierge models may vary.",
            "Over the last several years, the insurance companies have become more demanding upon what physicians  are  required  to  do  in  order  to  be  compensated,  including  preapproval  for  specific tests  ordered  and  for  many  prescribed  medications. Insurance  companies  have  also  set guidelines for documentation requirements and if not done correctly, a financial penalty may be assessed by the insurance company. In other words, I may spend 40 minutes with a patient and do a very thorough examination, but if I forget to write one guideline point in my notes, I could be financially penalized. These audit penalties can run into the thousands of dollars if a full audit is done. As a result, the focus of physicians is shifting from talking and listening carefully at each visit  to  documenting. Due  to  time  constraints,  some  physicians  are  now  forced  to  spend  the office visit typing away on their laptops in the exam room instead of communicating eye to eye with the patient. It seems as though the focus of insurance companies  has shifted from quality patient care to that of documentation and pre-authorizations.",
            "The  insurance  companies  continue  to  cut  services  covered  for  patients  while  also  cutting  the amount of payment to the physicians. Each year, it seems as if the reimbursements drop yet the cost of running an office increases. In order to compensate for this, there is increasing pressure to  see  more  patients  daily. In  order  to  increase  patient  volume,  less  time  is  allotted  to  each appointment. In my practice, the average patient age is in the 70-80s and many of them have multiple complex medical conditions and over 6-10 medications being taken. It isn’t realistic for me to be able to see a patient for 10 to 15 minutes and cover all their conditions. I am not willing to compromise quality care nor will I accept the increased chance of making a medical error in my rush to see the next patient.",
            "On  top  of  a  full  day  of  appointments,  time  also  has  to  be  set  aside  for  reviewing  lab  results, radiology imaging, doing pharmacy refill requests and answering patient phone calls. All of this is done during lunch breaks or after work hours in the evening. Chart documentation also has to be completed usually within 24 hours, and this is on personal time which is also not financially compensated, and family time suffers. Physician burnout is increasing as a result.",
            "My last employer suddenly closed their doors without notice in October of last year. It was a terribly  handled  process  that  was  difficult  for  the  patients  as  well  as  the  staff.   It  did  however make  me  realize  that  something  had  to  change.   I  was  not  going  to  be  placed  in  that  position again so it was time for me to be my own boss. I looked at the concierge model and realized that this was the solution.  It removes the insurance companies completely from the equation.  Patients keep insurance for hospital care, seeing specialists, lab tests and radiology orders. By doing so, I am able to fulfill my mission of seeing patients and treating them as if they were family members. I am able to be detailed oriented and thorough while not being worried about running to the next exam room. There is a misconception that concierge is about making money. It is truly about standing up to the present state of medicine filled with time limits and rushed care and saying that this is not acceptable. It is about making quality of care the goal.  My focus is the patient, not the insurance.  My patients now have the ability to contact me 365 days a year by my cell phone, text and personal email.  If they are part-time we still have office visits with tele-medicine.",
            "In  a  traditional  practice,  a  primary  care  physician  may  have  as  many  as  5000  patients. Most physicians haveno time to do anything but focus on outpatient care only. They rely on their staff to call patients back and follow-up on issues for them. In a concierge practice, there is usually a maximum  of  300  patients,  a  vast  difference.   As  a  result,  availability  increases. Time  for appointments   increases   to   1-2   hours   if   needed. Complete   personalization   of   care   is accomplished and detailed because of the additional time. I have time to visit my patients at the hospital and care for them at their home for emergencies. I have the time to call all of my patients back to review results, see how they are doing in general, and answer any questions that they have.",
            "There is a fallacy that concierge care is reserved for the wealthy.  Many of my patients are not wealthy but simply realize that this is the type of care that they want. They also have had enough. They  are  frustrated  with  not  hearing  back  from  offices,  not  being  able  to  get  an  appointment, and  for  having  rushed  appointments. They  realize  that  by  investing  in  their  health,  they  are investing in their wellness.",
            "It is important to make clear that I am not saying that concierge physicians are better physicians. We  are  equals  to  our  traditional  model  colleagues. We  simply  have  the  luxury  of  more  time. I stand tall next to my traditional model colleagues remembering what they are going through, but I am thoroughly grateful of the present path that I have chosen.  If a concierge approach would better suit your medical needs, please consider seeing one of the concierge physicians in your local area.",
        ]
    },
    [Articles.DEPRESSION_ITS_NOT_JUST_A_CELEBRITY_TREND]: {
        ...ArticleMetadata[Articles.DEPRESSION_ITS_NOT_JUST_A_CELEBRITY_TREND],
        text: [
            'The recent celebrity suicide deaths of Kate Spade and Anthony Bourdain have brought attention to the very important subject of depression. This psychological condition is very common, and according to the National Institute of Health it affected over sixteen million people in the United States in 2016. Symptoms may include feelings of sadness, loss of interest in pleasurable activities, hopelessness, thoughts of death, or a loss of energy. There also may be sleep and pain issues. These symptoms are usually ongoing for at least a period of two weeks if not more.',
            'There are many risk factors that may trigger the onset of depression such as experiencing a major stressful event or a severe trauma. Other examples may include a tragic family death or the sudden loss of a job causing large financial losses. Relatives with a history of depression or alcoholism also increase the risk. Depression is found to be highest between the ages of 18 to 25 and tends to affect women at a higher rate than men. Certain medical conditions are also linked with an increased risk for depression. These include Parkinson’s, heart disease, diabetes and cancer. Finally, certain medications have side effects that may increase symptoms of depression. Some pain relievers, blood pressure and sleep medications are culprits. Hypothyroidism may even show false signs of depression but when the thyroid condition is treated, these symptoms tend to disappear. This is why checking your bloodwork for your thyroid level is part of the testing done when being evaluated for depression.',
            'Depression is optimally treated with both medical therapy and psychotherapy. Other options are available if this approach fails. As with so many other medical and psychological issues, a healthy diet and exercise program does help. It is important to allow 8 hours in your sleep schedule. Consider putting off making any serious decisions until you are feeling better.',
            'Sadly, as many as a third of people suffering from depression fail to seek any treatment at all. Please speak to your physician if you are suffering from any symptoms of depression or thoughts of death, and remember that it is so important to reach out to your family and friends for support. Most importantly, please know that you are never alone. Help is always a phone call away by simply calling the US National Suicide Prevention Hotline at 1-800-273-8255.',
        ]
    },
    [Articles.SKIN_CANCER_RISKS_IN_THE_FLORIDA_SUN]: {
        ...ArticleMetadata[Articles.SKIN_CANCER_RISKS_IN_THE_FLORIDA_SUN],
        text: [
            "Naples is known as SW Florida’s paradise filled with sun and fun.  Every year thousands of students flock to our beaches yearning for the ultimate tan during spring break.  Many do not realize the impact on their future that this exposure of intense sunlight carries. Skin cancer is the most common cancer in the United States.",
            "It occurs because there is an abnormal growth of skin cells in the outer most layer of the skin, called the epidermis.  Triggers causing these abnormal skin changes usually involve exposure to harmful ultraviolet (UV) rays from the sun or a tanning bed.  The UV exposure increases the presence of free radicals.  This in turn increases inflammation locally and causes damage to the skin cell function and DNA.  Mutations form causing the abnormal growth of skin cells.",
            "Per the American Academy of Dermatology, one in five Americans will have skin cancer. Approximately 9500 new skin cancers are diagnosed each day.  It is one of the most common cancers in teenagers.  Many do not understand that the amount of sun exposure that one has in childhood and adolescence may determine the risk of having skin cancer later in life.  The importance of sun safety needs to be emphasized.",
            "The four most common skin cancers include basal cell, squamous cell, malignant melanoma and merkel cell.  Basal cell is the most diagnosed form of skin cancer with about 4 million cases annually in the US.  Fortunately, it is the least invasive and easy to address.  It is usually caused by short intense exposures to UV rays causing sunburns in addition to chronic exposure to the sun.  It tends to form on the face, scalp, ears, back and shoulders.  Signs that this may be present include a sore or bump that does not heal or it may show as a reddish patch as well.",
            "Squamous cell skin cancers tend to be more invasive compared to basal cell. Unlike basal cell cancers, they may appear in areas where the sun does not reach.  UV exposure to the sun and tanning beds tend to be the culprits. This skin cancer is diagnosed about 1 million times annually. Unfortunately, as they are more invasive, if not caught early they may metastasize and go elsewhere in the body.  This is why there are approximately 15,000 deaths from squamous cell skin cancer annually in the US." +
            "Malignant melanoma is a skin cancer that should be feared.  Although not as common as basal and squamous cell skin cancer, the risk of death is higher. Per the American Cancer Society, about 100,350 new cases of melanoma is expected to be diagnosed in 2020.  They also predict 6850 deaths because of it. Melanoma develops in the melanocytes of the skin which is what usually produces pigment.  As a result, it may simply appear to be a mole and be ignored. Sunburns from sun exposure and tanning bed UV rays have been blamed as the main triggers as well.",
            "Merkel cell skin cancer is quite rare but dangerous because of its aggressive nature. Per the AAD it is seen about 2500 times a year but is responsible for 700 deaths. It is felt to be triggered from a virus called merkel cell polyomavirus.  It usually appears as a painless nodule on the sun exposed area that does not go away.  It is important to diagnose this skin cancer early in order to improve survival chances.",
            "It is a myth that only certain skin color can be at risk for skin cancer.  Although fair color skin tones are higher at risk as they burn more easily, skin cancer is also seen in dark skin tones.  Per the ACS, melanoma is seen more than 20 times more in white people than African Americans. Other risk factors include having a large amount of sun exposure or sunburns earlier in life, increasing age, and those with blond or red hair.  Tanning bed use significantly increases the risk of skin cancer. Gender also plays a role in risk.  Most cases of malignant melanoma under age 50 involve women where the opposite holds true after age 65 where men are mostly involved. Other risk factors include having had skin cancer before, those with atypical moles and even those with weakened immune states.",
            "As the risk for skin cancer is largely based on early age exposure to the sun, sun safety needs to start in childhood.  The importance of decreasing sunburns and exposure the harmful UV rays needs to be emphasized in childhood.  Tanning beds need to be avoided at all costs. The importance of repeated sunscreen use throughout the day when outdoors needs to be spread.  The availability of sun protective clothing, large hats and even window films for car windows have been helping. Because of the issue involving free radicals, there are some that strongly advocate the addition of certain antioxidant vitamins.  They include vitamin C, vitamin E, vitamin A, zinc, omega 3 and beta carotene.",
            "Please see your dermatologist yearly for your total body skin exams.  Also, keep an eye on your skin for any new atypical moles, bumps or sores that never heal.  There is a good chance you may discover your own skin cancer which may be life-saving.  Remember to protect your eyes with the use of sun glasses. Use sun screen daily and reapply often when out in the sun. Being an active part of your own health care is really important.  Remember, always be proactive and not reactive."
        ],
    },
    [Articles.OSTEOPOROSIS_A_SILENT_CULPRIT]: {
        ...ArticleMetadata[Articles.OSTEOPOROSIS_A_SILENT_CULPRIT],
        text: [
            "Osteoporosis is a very common bone disorder that slowly and silently causes the progressive loss of bone density. As the bone becomes less dense, it increases the risk of a fracture even without significant trauma occurring. According to the National Osteoporosis Foundation, osteoporosis affects about 54 million Americans. As many as 1 in 2 women and 1 in 4 men above age 50 will have a fracture caused by it. Unfortunately, fractures do not only cause pain. Because of the immobility caused by it, some complications may include infection, blood clots and in extreme cases can possibly lead to death.",
            "There are risk factors associated with osteoporosis. It impacts women more than men with the older population being at a higher risk. Estrogen is a protective factor so post-menopausal women taking \"anti-estrogen\" medications, as seen in breast cancer treatments, increases the risk. Drinking too much alcohol or smoking also increases the risk so avoiding this is important. Family history of this disorder can also increase the chances for osteoporosis.",
            "Certain medical conditions are also culprits in increasing the risk for bone density loss. They include rheumatoid arthritis, lupus, celiac disease, certain cancers, hyperthyroidism, kidney disease and hyperparathyroidism are just a few. Certain medications have been linked as well. These include chronic steroids, methotrexate, certain epilepsy treatments (i.e. Dilantin), and hormonal prostate cancer and breast cancer therapies. Medications for acid reflux have been shown to possibly lower the magnesium level which is bad because magnesium is necessary for calcium to work effectively. Calcium is necessary for maintaining strong bones. Therefore, magnesium levels need to be checked if acid reflux medications are being taken.",
            "To help protect against osteoporosis, it is important to get the proper amount of both calcium and vitamin D daily. The average calcium amount per day should be 1200mg, taking into account both dietary forms and supplements. Common food sources include milk, yoghurt, cheese, orange juice, beans and almonds. Less calcium is advised for those who have had kidney stones previously or have a parathyroid condition. The usual vitamin D amount advised is 400-800 units daily, but this amount is changed if the vitamin D blood level is found to be low. Weight bearing exercises are very helpful while also working to increase your balance and the prevention of falls. A very common time to fall is in the middle of the night while going to the bathroom Tripping over items on the way in the dark, while you are sleepy, is a common problem. Make sure to have a clutter free path aided with the use of nightlights.",
            "Getting your bone density checked is vital. It usually begins at age 50 in women and 65 in men. Those ages may be lower for those at a higher risk. The bone density is usually checked every 2 years if abnormal. It is important to realize that osteoporosis is a treatable condition with many options to choose from. Please make an appointment with your primary care physician to review your risks, and get tested if you are due. And remember, even though this condition is silent, it can cause great pain and harm if it goes untreated. Do take an active step in your health and find out what your bone density is. Prevention does save lives.",
        ],
    },
    [Articles.COLON_CANCER_PREVENTION_MY_EXPERIENCE]: {
        ...ArticleMetadata[Articles.COLON_CANCER_PREVENTION_MY_EXPERIENCE],
        text: [
            "As a primary care physician specializing in internal medicine, it is part of my daily patient conversation to discuss wellness and cancer prevention. When reviewing colon cancer prevention with my patients I always advise that a colonoscopy be conducted when appropriate based on their age and family history. Most people follow through with recommendations in order to be proactive. However, there are always a few who procrastinate stating that there is no one in their family with colon cancer or they are just too busy. I am hoping that my story changes this.",
            "When I turned the age of 50, I scheduled my colonoscopy with my gastroenterologist. I agree that the liquid prep isn’t the most pleasant tasting but this is only for a few hours the night before the procedure. It is doable. I have no family history for colon cancer, yet to my surprise a precancerous colon polyp was removed. If I had procrastinated because of my busy schedule, I would have been diagnosed in the near future with colon cancer. As the polyp was removed, I successfully prevented colon cancer. This is exactly the purpose of a colonoscopy.",
            "The statistics for colon cancer per the American Cancer Society are sobering. Colon cancer is the 3rd most common cancer for both men and women. They estimate a lifetime risk for colon cancer of 1 in 22 for men and 1 in 24 for women. Fortunately, the death rate has been decreasing. Reasons for this include more colonoscopies being conducted and better cancer treatments are now available.",
            "Not all colon polyps are precancerous in nature. The adenomatous polyp is cancerous as opposed to a hyperplastic polyp which is benign and has no cancer potential. Most colon cancers are adenocarcinomas. Less common types of cancers in the colon include lymphomas, carcinoids and sarcomas.",
            "Risk factors for colon cancer have been identified. They are broken down into risks that are adjustable, and those that are not. Examples of the latter include family history of colon cancer or precancerous polyps as well as if you already had precancerous polyps. Those who have inflammatory bowel disease such as ulcerative colitis also have an added risk. African Americans and Jews of European descent have higher risks as well.",
            "Risks that are adjustable include obesity, heavy alcohol intake, smoking and being sedentary. Diets that are rich in meat and that are highly processed increase the chance as well. There are also possible increased risks seen for those that are night shift workers, men with prior testicular cancers as well as those men who were treated with radiation for prostate cancer. More research is needed however to confirm these possible risk categories.",
            "Prevention, as I always state, is so important. It is essential that you speak to your primary care physician about your risks and when your screening is due. Although the previous standard was for the average risk person to start screening at 50, the ACS has now lowered the starting age to 45. The question is if health insurances will agree to this advised new age. It is recommended for those in the average risk to have a colonoscopy every 10 years between the ages of 45-75. From 76-85, the colonoscopy is based on risk. No further screening colonoscopies are routinely done above age 85 but may be done if an acute problem occurs such as significant rectal bleeding.",
            "The start age for higher risk people is individualized and is repeated in frequency depending on the findings. The longest time between colonoscopies is usually 5 years. Another test available for those people that have no polyp or family history for colon cancer is a Cologuard stool test which is a specific DNA study for precancerous polyps, and it is done at 3 years intervals.",
            "I encourage you to keep up with your annual wellness physicals with your primary care physician. Discuss when all of your prevention cancer screens are due and get them done. Work on your diet to make it low in fat and carbohydrates but rich in lean protein and fruits. Keep up with physical exercise 3 times per week. Avoid smoking and drink in moderation. I hope that you realize through my experience that procrastination is never good. Please get your colonoscopy done in a timely fashion. It may save your life.",
        ],
    },
    [Articles.HEADACHE_BASICS]: {
        ...ArticleMetadata[Articles.HEADACHE_BASICS],
        text: [
            "A very common problem reported at a doctor’s visit involves a headache. There are many different causes so details about the headaches are very important in order to figure out the culprit.",
            "Tension headaches, previously known as stress headaches, are the most common cause of headaches. They usually involve both sides of the head and are mild to moderate in intensity. They are not associated with other issues like nausea or vomiting. They usually occur infrequently but are triggered by stress. It is believed that the tension may cause muscle contractions in the pericranium (layer covering the skull) which is why there may be reproducible tenderness on certain muscles involving the head, neck, and shoulders on exam. Treatment usually includes anti-inflammatory medications, stress relaxation techniques, and ice packs.",
            "Migraines are severe recurring headaches usually associated with nausea and possible sensitivity to light and sounds. It affects approximately 12% of people and may start at a young age. It was initially thought to be caused by the vasodilation of blood vessels in the brain; however, it is now felt to be from activation of several pathways in different parts of the brain. The end result of the activation is the release of neuropeptides which promote inflammation. Migraines are more frequent in women. Possible triggers include stress, hormonal flux in women, hunger, weather changes, food sources, neck issues, lighting, alcohol, and being overtired. Food triggers include excessive caffeine, red wine, aspartame, aged cheeses with tyramine (i.e. brie, blue cheese, swiss, etc.), and MSG. There are medical therapies to try to prevent and treat migraines. They include prescription medications, procedures, and even over the counter supplements like magnesium.",
            "The following headaches should be reported to your doctor as they may be linked to other conditions. Cough headaches occur when coughing or straining. Stabbing headaches that are sharp and jabbing in nature may even make the person wince. Exercise-induced headaches have been seen with malignancies. Headaches during sex tend to be benign in nature and caused by tension in the neck and jaw during sex. Trying to relax them during sexual activity helps. However, headaches induced suddenly by orgasms should be reported as they are linked with the risk of strokes. A common and benign headache is caused by eating something cold. This has the slang of \"brain freeze\" headaches from eating cold things like ice cream. Another headache may be caused by sinus congestion and is usually present with nasal congestion and drainage.",
            "The following headaches need immediate evaluation in an emergency room setting. Acute onset \"thunderclap\" headaches occur suddenly and severely. They may be linked to life-threatening conditions like brain hemorrhages or thromboses. If fever is present, meningitis needs to be ruled out. When discussing headaches with your physician it is important to note how old you were when they started, how often they occur, what else happens with them (i.e. nausea), what worsens them (i.e. sound, light), what improves them, and what triggers them (i.e. foods, activities, etc.). Try to see if there is a pattern on when they occur. Family history is also important. What you describe and the physical exam will be important to help diagnose the type of headache present. Brain imaging may be needed depending on the headache. Treatment and prevention methods are formulated once the diagnosis is known.",
            "To help prevent headaches try to get enough sleep, exercise regularly, and try not to skip meals. Watch the dietary triggers listed above, and practice self-relaxation techniques. Caffeine is tricky as it may both alleviate and trigger headaches. Try to avoid having too much caffeine and stopping it \"cold turkey\" if you drink it regularly.",
            "Please talk to your doctor if you are having frequent or worsening headaches. Seek immediate attention if there are any sudden and severe headaches or if they are linked with a high fever. Headaches are very common, and disruptive to us all, but with the proper treatment and preventive measures, we can manage and reduce their harm.",
            "Dr. Sciavolino-Day is a board-certified physician specializing in internal medicine. For more information about her concierge medical practice in Naples, please visit her website at www.drsciavolinoday.com.",
        ],
    },
    [Articles.HEART_DISEASE_UPDATE_THE_IMPORTANCE_OF_AWARENESS]: {
        ...ArticleMetadata[Articles.HEART_DISEASE_UPDATE_THE_IMPORTANCE_OF_AWARENESS],
        text: [
            "Heart disease is the leading cause of death for both men and women in the United States. The updated statistics from the CDC continue to be sobering. Heart disease caused 840,768 deaths in 2018. Every 40 seconds, someone is having a heart attack. The only good news is that the death rate from heart disease has decreased by a third. Awareness of the problem and taking steps to decrease risk factors has proven to be very effective.",
            "So what are some of the possible warning signs of heart disease? These may include chest pain, shortness of breath, jaw pain or even vague symptoms such as increasing fatigue, back pain and heartburn. As such, it is important to see your physician for your wellness exams in order to evaluate what your risks are and to help in determining if any of the above symptoms are serious in nature.",
            "Common risk factors include older age, diabetes, high blood pressure and high cholesterol. Habits that negatively affect heart disease include unhealthy eating habits, smoking, excessive drinking and a lack of exercise. Family history especially increases risk if a father or brother was diagnosed with heart disease before the age of 55. Risk for women increases after the age of menopause. Other possible risks include sleep apnea, autoimmune diseases such as rheumatoid arthritis, and those who have an elevated homocysteine level.",
            "It is essential to take an active role in your wellness approach so try to reduce your risks as much as possible. You cannot control family history or age but you can help in other ways. Optimize your nutrition by having a diet rich in vegetables, fruit, whole grains, fish and lean meats such as chicken and pork. Try to avoid foods that are processed or filled with sugar and corn syrup. Hydrate well, and limit caffeine and alcohol. Women of all ages and men above the age 65 should limit alcohol to 1 glass daily. Men under age 65 have a limit of 2 glasses. If you are a smoker, it’s time to stop. Although many smokers turn to electronic cigarettes (vaping) to help stop smoking cigarettes, the FDA advises avoidance of these products until more studies are available regarding their safety. Staying physically active with exercise is important so speak with your physician about the best fitness program and nutrition plan for you.",
            "Other items that may need to be addressed include keeping your blood pressures under 130/80. Get your annual bloodwork done to evaluate for diabetes and see where your cholesterol levels are. If you have diabetes, work on getting the specialized diabetes lab tests to goal and include abdominal core exercises in your fitness program. The bad cholesterol, known as the LDL, should be less than 100 in the average person, and less than 70 in those with history of heart disease, strokes, vascular issues and diabetes. Often times, cholesterol medications are needed if these higher risk conditions are present.",
            "Stress control is very important. Stress may influence cortisol levels and interrupt sleep, but it has also been known to raise blood pressures and sugar levels in those with hypertension and diabetes. It is important to address all stress issues. Please do not hesitate to discuss this with your physician and see if you would benefit from seeing a psychotherapist or starting medical therapy.",
            "Aspirin use has been in some recent controversy. The risk for bleeding has been shown to be higher in average cardiac risk aspirin users and therefore has been advised not to be used unless recommended by your doctor. If you have had cardiac or vascular history or significant risk factors, this new study finding does not apply to you and aspirin is beneficial. Please do not stop aspirin or medical therapy on your own without speaking to your physician.",
            "As an internist, I order many cardiac score CT scans to assess how much calcified plaque is in the coronary arteries. This is a very quick and non-invasive CT scan available to the public with a physician order. If this is abnormal or if cardiac symptoms are present, a cardiac pet scan or nuclear stress test may be ordered to see if there is plaque blocking blood flow in a cardiac artery. If so, a cardiac catheterization would be performed by a cardiologist to open this blockage in order to prevent a heart attack.",
            "The best approach is to take an active role in your health. Please call your physician today to schedule your wellness visit and always seek help for new warning signs. Ask questions in order to understand what your lab tests show and if they are not optimal. Always address stress issues and discuss sleep problems with your doctor. My patients hear me repeat the following sentence quite often. Be proactive in your health and not just reactive. Prevention does save lives.",
            "Dr. Sciavolino-Day is a concierge internist located in North Naples. More information may be obtained at www.drsciavolinoday.com."
        ],
    },
    [Articles.GETTING_TO_THE_BOTTOM_OF_CONSTIPATION]: {
        ...ArticleMetadata[Articles.GETTING_TO_THE_BOTTOM_OF_CONSTIPATION],
        text: [
            "As an internist my patients commonly report bowel movement problems involving constipation. What’s important to realize is that constipation is a symptom and not a medical condition.  Most people will experience constipation at one time or another, and once the cause of the issue is resolved things should return to normal. Now although there is no set mandatory number of bowel movements or how often they should be, everyone’s usual pattern is their own individualized norm.  If the stools get harder or it takes longer than usual to have another bowel movement, then constipation is present.  Often times, someone is done having a bowel movement but there is a lingering feeling that there is still more that needs to come out. As a result, bloating, flatulence and abdominal fullness may follow.",
            "There are many possible causes for constipation. Obviously what a person eats or doesn’t eat will have an effect.  The recommended daily fiber intake ranges from between 20-35 grams. Constipation is likely to occur if this recommended amount is not being met. Fiber may be obtained from dietary sources or from over the counter supplements.  Foods high in fiber include: apples, pears, oats, lentils, chia seeds, avocado, broccoli, carrots and strawberries.",
            "A less than optimal diet that is high in fats and sugars may also cause constipation.  It is important to follow a low fat diet and watch your carbohydrate intake.  The recommended daily fat intake should be no more than 20-35% of your daily caloric intake, and carbohydrates should be limited to about 45-65%. Try to choose lean proteins, fruits, vegetables and whole grains, and limit the amount of foods with added sugars and refined grains.  Physical exercise also helps to get things moving.  Hydrating well is also important. If despite working on all of the above the issue persists, it is important to see your physician.",
            "When I assess constipation issues the first thing that I review after looking at diet and hydration is what medications are being taken.  Certain medications may cause constipation as a side effect.  Some of these include medications for bladder control, Parkinson’s, pain control and hypertension.  Over the counter medications may also be the culprits.  These may include calcium, iron and certain antacids. If you are taking a laxative for an extended period of time, this will cause the body to stop having bowel movements on its own as the chemical laxative has been inducing it.  Your body simply becomes dependent on it in order to have a bowel movement.",
            "Certain medical conditions need to be ruled out as the cause.  Hypothyroidism, when the thyroid slows down in its thyroid hormone production, may cause constipation as a symptom.  A simple blood test could diagnose this issue. Nerve issues caused by diabetes or strokes may also be a cause.  An obstruction or physical structural issue present in the bowel may also need to be ruled out.  Finally, there is a common condition called irritable bowel syndrome that for many sufferers causes chronic intermittent flip flopping between normal stools and constipation.  Treatment for the above conditions differs.  Sometimes imaging and a colonoscopy performed by a gastroenterologist are required for a more thorough diagnosis.",
            "In summary, constipation is a symptom of an issue.  It is not a condition by itself.  It is important to define the cause in order to resolve it.  Have a diet rich in fiber or add a supplement if needed.  Hydrate well and keep physically active.  Try to avoid the chronic use of chemical laxatives.  If the issue persists despite the above, please make an appointment to see your physician.  Sometimes chronic constipation is the first symptom of a larger problem that needs to be addressed.  As I always state, it is better to be proactive as opposed to reactive.  Never assume that a problem that is persisting will work itself out so please have it addressed so it may be resolved."
        ],
    },
    [Articles.ADDRESSING_YOUR_CHRONIC_THROAT_CLEARING]: {
        ...ArticleMetadata[Articles.ADDRESSING_YOUR_CHRONIC_THROAT_CLEARING],
        text: [
            "One of the more frequent symptoms that I address as an internist is chronic throat clearing. Sometimes I’ve had a patient do this throughout an entire office visit and they don’t even realize it because it has become so commonplace to them. Other times, family members or spouses will roll their eyes and state the patient does it all the time, annoyingly. Although there are additional reasons, I have found two primary causes in my twenty year clinical experience thus far: Laryngopharyngeal Reflux and Allergic Rhinitis.",
            "Laryngopharyngeal Reflux (LPR) is quite common. It is caused by a reflux (GERD) of acid going from the stomach up the esophagus all the way to the throat. Only about half of people with this condition also complain of heartburn. They may experience chronic throat clearing, hoarseness or even a simple persistent cough. The exposure of the esophagus to acid repetitively may increase the risk for a Barrett’s esophagus which carries a precancerous risk so it is essential to diagnose and treat this condition.",
            "The LPR risk goes up with an increase in abdominal weight. Spicy foods, caffeine, alcohol and even breath mints may serve as food triggers. Tight fitting clothing around the waist can also trigger the reflux. Medical therapy is available with quick acting medications such as Zantac or Pepcid but for more serious cases, stronger acid lowering medications like omeprazole and pantoprazole could be used. Most cases can be treated with a change in lifestyle habits. LPR patients are encouraged to lose weight and avoid foods rich in spice, acid and fats. Caffeine and alcohol are also minimized. Smoking should be avoided. Loose clothing is advised as well as stress control. Reflux patients are encouraged not to lie down for 2 hours after eating. Sleeping propped up also helps. In most cases, the approaches listed here will help cure LPR.",
            "Another common cause of chronic throat clearing is allergic rhinitis. There is a nasal reaction to an allergen which then causes histamines to be further released. This in turn causes nasal congestion, sneezing or a post nasal drip. It is this post nasal drip that gives rise to throat clearing. Obviously, if the patient can avoid what is causing the allergic reaction it would be beneficial, but this may be difficult if the allergen is mold and it is the rainy season or pollen during the springtime.",
            "Nasal saline spray is used to help treat the nasal drainage and congestion. This may also help remove the allergens from the nasal mucosa as well. As histamine release is the main issue causing symptoms, over the counter anti-histamines (i.e. Claritin, Allegra, Zyrtec, etc.) could be taken to help fight off the allergic reaction. Nasal steroids are also commonly added to dramatically relieve the nasal swelling and nasal symptoms. For more significant allergy sufferers not helped by these initial forms of treatment, an allergist may start a series of allergy shots to help decrease the reaction to the allergen in time.",
            "When I evaluate someone with throat clearing, just asking some basic questions can tell me a great deal. When is it worse? What kicks it off? What helps? Is it positional? Does food trigger it? For those patients that do not respond well to the treatments I’ve listed here, I refer them to an ENT specialist for direct visualization of their vocal cords and if needed, the sinuses. In either case, it is important to answer the question: acid reflux (LPR) or post nasal drip or a combination of both? If LPR is the cause, getting control of the acid and preventing Barrett’s esophagus is essential. Therefore, please take your throat clearing seriously and discuss it with your primary care physician or ENT specialist."
        ],
    },
    [Articles.LOOKING_OUT_FOR_DOMESTIC_VIOLENCE]: {
        ...ArticleMetadata[Articles.LOOKING_OUT_FOR_DOMESTIC_VIOLENCE],
        text: [
            "Next time you are at work, at a restaurant or maybe walking on the beach, look around you. There is a possibility that there may be a victim quietly suffering. The statistics are sobering. Per the US Department of Justice, at least 3 women are murdered per day by their spouse or boyfriend. About 36% of women and 29% of men have experienced rape, stalking or physical violence by their partners in their lifetime. Per the National Domestic Violence Hotline, more than 24 people per minute are victims of domestic violence which results in over 12 million cases per year in the United States alone. It’s a tragedy that constantly needs to be recognized and addressed in hopes of stopping the abuse before it occurs and for treatment of the victims. Most people believe that domestic violence involves only physical abuse. In actuality, it also includes the attempt to control their partner physically, psychologically, financially or socially.",
            "So who are the victims living and working among us? As stated previously, they can be women or men. Younger women tend to make up the majority of victims, but this crime has no barriers as it even occurs among the elderly. Domestic violence happens regardless of race, religion, socio-economic status or level of education." +
            "To complicate matters, there are ripple effects. There is a known link between domestic violence and child abuse. Per the US Department of Justice, over the duration of one year about 1 million children are exposed to serious violence such as being kicked or beaten. They are also impacted by what they see happening within their family. In an analysis of court reported cases, children witnessed the violence about 22% of the time. This increases the risk of these children experiencing psychological issues such as post- traumatic stress disorder. Sadly, one study found that about 9% of teens admitted that they had been struck purposefully by their boyfriend or girlfriend within the last year. Please reach out to your teenage children about this troubling statistic.",
            "It’s important to look out for warning signs that your friends, family members or co-workers may be showing you. Look for unexplained bruising or marks. Pay attention to those who constantly worry about their actions possibly irritating their partner or appearing disobedient. Pay attention if they are becoming isolated and avoiding friends or family.",
            "To those who may be victims, please realize the following: You need to be concerned if your partner starts showing aggressive warning signs of either physical or psychological abuse. Examples may be constantly belittling and putting you down. Access to finances may be taken away from you. Other examples include showing you signs of jealousy if you spend time with family and friends. This may escalate with threatening to take the children away from you, or forcing you to have sex against your will. Please understand that love does NOT include physically hurting you or your children.",
            "Please keep up with your medical appointments. Per the CDC, women of violence have higher medical risks in their lifetime. There is an 80% higher chance of having a stroke and a 70% higher risk for having a heart attack. Unfortunately, women abused are 80% more likely to drink heavily which has possible medical complications from that alone.",
            " If you are experiencing any of the above warning signs or being physically abused, please call either of the following numbers for more information when you are in a safe place. Remember, you are not alone. Someone is always available 24 hours a day by calling the numbers below.",
            "It is important to offer support to possible victims but also to let them know to contact the National Domestic Violence Hotline or the Florida Domestic Violence Hotline. It would be helpful to have these numbers posted in every bathroom stall at all public sites, jobs and schools. These numbers are listed below.",
            "The more that it is discussed, the better the chance that a victim may be recognized and be able to reach out for help.",
            "National Domestic Violence Hotline: 800-799-7233",
            "Florida 24-Hour Domestic Violence Hotline: 800-500-1119",
            "Dr. Sciavolino-Day is a concierge physician specializing in internal medicine. More information may be obtained at www.drsciavolinoday.com or by calling 239-596-8702."
        ],
    },
    [Articles.OCTOBER_COVID_EMAIL]: {
        ...ArticleMetadata[Articles.OCTOBER_COVID_EMAIL],
        text: [
            'Good morning,',
            "I hope that you are doing well. I attended a webinar conference hosted by the Collier County Medical Society last night. One of the infectious disease specialists from NCH gave an hour-long summary about the present status of Covid-19 as well as reviewing the up to date studies about its treatment. It was an excellent presentation, and I want to give you a summary:",
            "The new infection numbers have improved- reinforcement was given to use masks, wash hands, and maintain social distancing as they are helping.",
            "Dr. Witherell discussed the difference between rapid antigen testing and the preferred nasal swab PCR testing. Rapid antigen testing is being done in areas where there are large groups of people (i.e. schools, retirement facilities, hospitals, urgent care centers, etc..) and faster results are needed. Unfortunately, they are not as accurate as the nasal PCR testing which take about 3-12 days to return. The recommendation was that if a person does a rapid antigen test and has a positive result, that is to be taken as a positive. If the result is negative but a Covid-19 exposure happened or infection symptoms are present, then a confirmation test needs to be sent out via nasal swab PCR testing. Until the result returns, that person should self-isolate at home.",
            "If someone is found to be positive for Covid-19, they need to self-isolate. Those who have mild to moderate symptoms (=not requiring hospitalization) need to self-isolate for 10 days- they may stop the self-isolation after that time if there has been no fever for over 24 hours and the infection symptoms have improved. If someone was hospitalized for a severe Covid-19 infection, then the self-isolation is a total of 20 days after the 1st symptom. Those that are Covid-19 positive but remain completely asymptomatic need to self-isolate for a total of 10 days. The purpose of self-isolating is to decrease the spread of infection to others.",
            "Outpatient Treatment: There is NO prescription treatment for mild to moderate COVID-19.",
            "Supportive care is advised (fever reduction, hydration, decongestants for cough/congestion, etc..). Self-isolation is essential. Of note, sleeping and lying down in the prone (face-down position) is helpful. There has been NO proven benefit with the use of antiviral therapy in mild to moderate disease.",
            "Treatment of severe Covid-19 infection: hospitalization is advised for those who start to drop their oxygen levels below 90% and require oxygen. Isolation precautions are started as well as placing in a prone position as much as possible. Steroids called dexamethasone are given for 10 days which has been shown to help the lower oxygenation problem that is going on. Antivirals like Remdesivir are offered but interestingly, they help to shorten the duration of the virus but have not been shown to impact the death rate from the infection.",
            "Outpatient vitamin supplements: there was a protocol devised by one center regarding possible helpful vitamins that may be used as prophylaxis and treatment. I had shared this protocol with you at an early Covid-19 time email. These supplements have NOT been shown to help but are not harmful so they are ok to take. Of interest, recent studies have shown that those with vitamin D levels at goal BEFORE getting sick did much better than those with low levels. There seems to be a protective effect of having an adequate vitamin D level before getting sick. Please take the vitamin D supplement advised by me at your routine visits and lab draws. Taking more than needed may raise the level too high and increase your chance of a kidney stone so please avoid this. Studies are ongoing regarding the benefit of vitamin D therapy during an acute infection.  Vitamin C is helpful only in IV form in the hospital. Oral over the counter forms of vitamin C have not been shown to help.",
            "Experimental Treatments: Plasma therapy made of antibodies of those that had a Covid-19 infection has been somewhat disappointing. There has been no clear benefit to date in those who have a normal immune system that mounts an antibody response to the infection or if it is late in the infection process. Those with complications early in the disease or who have a compromised immune system (i.e. those on chemotherapy) have been shown to have benefited from this treatment. As no harm has been found from getting the therapy, it is still an active option for those requiring hospitalization.",
            "There is an experimental therapy called Monoclonal Antibody Therapy which is what President Trump apparently received. This may provide antiviral help and a large number of studies are being done for possible prevention and treatment benefits.",
            "Hydroxychloroquine was initially felt to be a good treatment for Covid-19. This is NOT the case and is no longer being used in protocols. Also, the risk of possibly harmful side effects is high.",
            "Mask type: cloth masks need to be frequently washed. Masks with a valve on them are NOT safe. It is the equivalent of not wearing a mask. This type of mask will not be allowed to be worn in the office. N95 and surgical masks cannot be cleaned and need to be replaced frequently. The medical teams are advised to wear a new one daily.",
            "Shields: they may be placed in addition to a mask for eye protection, but are not to be used alone as they do not prevent inhalation of Covid-19 droplets from all of the open spaces. They should be cleaned daily.",
            "Vaccines: Of interest, both the Flu vaccine and MMR vaccines have been shown to have a small protective effect if a Covid-19 exposure occurs after getting the vaccine.",
            "Please make sure that your flu vaccine has been obtained. Please call the office at 239-596-8702 to schedule this.",
            "I found this webinar very helpful in explaining the present update of this terrible infection. Please let me know if you have any questions.",
            "Our \"season\" has started here in SW Florida. We are scheduling our appointments in a way of decreasing the chance of having multiple patients in the office at the same time. We will continue to follow the guideline of only having patients at the appointments. We will be asking family members and companions to wait in the car. If you have an appointment but have a fever, please let us know by phone as soon as possible. Covid-19 precautions will be ongoing in the office with cleaning protocols, hand washing, hand sanitizer, and having everyone present in masks. If your next follow-up appointment has not yet been set up, please call Debbi at 239-596-8702 to do so.",
            "I look forward to seeing you soon. Have a wonderful rest of the week.",
            "With Kind Regards,",
            "Cristina Sciavolino-Day, MD",
        ],
    },
    [Articles.STRESS_2020]: {
        ...ArticleMetadata[Articles.STRESS_2020],
        text: [
            'There is a common saying that people do not appreciate what they have until it is taken away from them. I think it is safe to say that this year has made this sentiment quite clear. The pandemic caused by Covid-19 has killed more than 1.4 million people so far globally. The quarantine has brought economic hardship, loss of jobs, significant social isolation, and increased stress. As a result, both anxiety and depression have become more prevalent. Rioting issues and the elections have compounded the problems further. One survey looking at the mental health impact caused by the pandemic was conducted by the World Health Organization (WHO). Stress, anxiety, and depression significantly increased and there was a greater need for mental health services; however, because of the quarantine, 93% of countries worldwide had a disruption in mental health services. These 2 opposing issues made it clear that attention was needed to address the mental health consequences.',
            'We have all been impacted in one way or another, but some have certainly had to deal with these stressors to a greater degree. This includes health care providers, first responders, those who have been infected with Covid-19, those with chronic medical conditions, seniors, and those who already suffer from a mental health issue. Those in racial and ethnic minority groups were also felt to be at greater risk. Seniors living in a nursing care or assisted living facility may suffer from isolation and the inability of seeing family members for extended periods.',
            'It is important to recognize what the signs of stress are so that you can be proactive and address them if they are present. Some signs include increased concern about the health of oneself or a loved one. Financial burdens may increase sadness and stress. Changes may occur in your sleep or eating patterns. You may notice you become more irritable easily. Sometimes people attempt to self-medicate by increasing alcohol, tobacco, or drugs. Look out for increased consumption of these products in yourself and your loved ones. Many medical conditions may worsen with stress. Examples include high blood pressure, diabetes, atrial fibrillation, and acid reflux.',
            'The following are some simple things that you can do to help relax and address stress:',
            '• Stop and take 5-10 deep breaths a few times a day. Listen to the sound of your breathing',
            '• Make sure to get some physical exercise with the goal of 30 minutes daily',
            '• Take advantage of the great Florida weather. I refer to it as sunshine therapy. Spend at least 15 minutes outdoors daily in the sunlight. Whether it be a simple walk, sitting and people watching, or just reading a book in your lanai, you would be surprised at how much better you may feel with time in the fresh and bright outdoors',
            '• Focus on proper nutrition. Try to eat more fruits, vegetables, healthy protein, and whole grains. Watch out for saturated fats and high carbohydrate-rich meals which may make you feel tired',
            '• Listen to calm uplifting music',
            '• If you have a pet, spend time playing with them daily',
            '• There are Apps that you can install on your smart phone or iPad which have calming sounds such as rainfall or ocean waves hitting the shore',
            '• Limit the news that you watch as the recurring negative news is not helpful',
            '• Watch comedies in place of serious dramas to help make you laugh',
            '• Because of social isolation, it is even more important to keep in contact with family and friends. Try to participate in facetime or zoom calls on your phone, iPad or computer',
            '• Do simple activities that relax your muscles such as neck exercises, hand massages, or taking a bath',
            '• If you cannot see your physician in person, consider a telehealth visit where your medical conditions and concerns could be addressed',
            'We all lead busy lives but remember how important it is to find time to relax and to work on your own self-care. You will be of no use to others if you do not focus on yourself first. Please reach out to your primary care provider if you feel down or overwhelmed despite trying the above techniques. Stress and depression may aggravate many chronic medical conditions so do not dismiss signs of their presence. Vaccines for Covid-19 are right around the corner and will help us deal with this novel virus. There truly is light at the end of this long and stressful year. If you become very down and do not know where to turn, the National Suicide Prevention Hotline is always available by calling 1-800-273-TALK (8255). Please remember that you are not alone.',
            'Dr. Sciavolino-Day is a primary care physician specializing in internal medicine. Please visit www.drsciavolinoday.com to learn more about her concierge practice in Naples.',
        ]
    }
};

export default ArticleText;