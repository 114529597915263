export const ADDRESS_STREET = '4085 Tamiami Trail North';
export const ADDRESS_SUITE = 'Suite B103';
export const ADDRESS_CITY_STATE = 'Naples, FL 34103';
export const EMAIL = 'info@drsciavolinoday.com';
export const FACEBOOK = 'https://www.facebook.com/DrSciavolinoDay/';
export const FAX = '(239) 596-8701';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyAM15fNMfr3aR47a9vBTxIdjGfp72b0kc8';
export const MAILTO_LINK = "mailto:info@drsciavolinoday.com?Subject=[Inquiry] Hello, I'd like to learn more!";
export const LINKEDIN = 'https://www.linkedin.com/in/cristina-sciavolino-day-md-309b453a';
export const OFFICE_LAT_LNG = { lat: 26.19665127428545, lng: -81.80098066459549 };
export const PATIENT_PORTAL_LINK = 'https://mycw103.ecwcloud.com/portal14045/jsp/login.jsp'
export const PHONE = '(239) 596-8702';
