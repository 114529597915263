import React from 'react';
import Icon from '@ant-design/icons';
import { Card, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { Image } from './common/Section';
import HighlightStar from '../images/star-icon.svg';
import './ArticleCard.scss';

const Star = () => (
    <div className='star-wrapper'>
        <Image imgSrc={HighlightStar} alt='Highlighted Article' />
    </div>
);

const ArticleCard = ({ highlighted, title, date, publication, id }) => (
    <Link to={'/articles/' + id}>
        <Card
            hoverable
            bordered
            className='article-card'
        >
            <Card.Meta
                title={
                    <Typography.Title level={3} className='article-title'>
                        {highlighted && (<Icon className='star-icon' component={Star}/>)}
                        {'     ' + title}
                    </Typography.Title>
                }
                description={
                    <Typography.Text>{date} &bull; {publication}</Typography.Text>
                }
            />
        </Card>
    </Link>
);

export default ArticleCard;