import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row, PageHeader, Typography } from 'antd';
import { useParams, Link, Redirect } from 'react-router-dom';
import ArticleText from "../data/ArticleText";
import {Section, SectionBody} from "./common/Section";
import './ArticleDetailsPage.scss';

const ArticleDetailsPage = () => {
    const { id } = useParams();
    if (!Object.keys(ArticleText).includes(id)) {
        return (
            <Redirect to='/articles' />
        );
    }
    const article = ArticleText[id];
    return (
        <Section>
            <Row>
                <Col offset={1} span={22}>
                    <PageHeader
                        backIcon={
                            <Link to='/articles'>
                                <ArrowLeftOutlined />
                            </Link>
                        }
                        onBack={() => ({})}
                        title='Back to Articles'
                    />
                    <div className='article-wrapper'>
                        <Typography.Title level={2}>{article.title}</Typography.Title>
                        <Typography.Title level={4}>
                            By Cristina Sciavolino-Day, M.D.
                        </Typography.Title>
                    </div>
                    <Typography.Text className='date-pub'>
                        First published in {article.date} &bull; {article.publication}
                    </Typography.Text>
                    <div className='article-text-wrapper' style={{ marginTop: '25px' }}>
                        {article.text.map((paragraph, index) => (
                            <SectionBody key={index}>{paragraph}</SectionBody>
                        ))}
                    </div>
                </Col>
            </Row>
        </Section>
    );
}

export default ArticleDetailsPage;