import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';
import { SectionBody, TextLink } from "./common/Section";
import { Centered } from "./common/Layout";

const QuestionAnswerEntry = ({ answer, question, resource }) => (
    <React.Fragment>
        <Row>
            <Col xs={{span: 24, offset: 0}} lg={{span: 6, offset: 2}}>
                <Centered>
                    <SectionBody><b>{question}</b></SectionBody>
                </Centered>
            </Col>
            <Col xs={{span: 24, offset: 0}} lg={{span: 14, offset: 2}}>
                <SectionBody>{answer}</SectionBody>
                {resource && (
                    <Centered>
                        <Link to={resource}>
                            <TextLink>Learn More Here</TextLink>
                        </Link>
                    </Centered>
                )}
            </Col>
        </Row>
        <Divider />
    </React.Fragment>
);

export default QuestionAnswerEntry;
