import React from 'react';
import { Carousel, Col, Divider, Row } from 'antd';
import GoogleMap from "./GoogleMap";
import { Image, Section, SectionTitle, InlineText, InlineLink } from "./common/Section";
import { Centered } from "./common/Layout";
import {
    FAX,
    PHONE,
    EMAIL,
    MAILTO_LINK,
    ADDRESS_STREET,
    ADDRESS_SUITE,
    ADDRESS_CITY_STATE
} from "../data/Constants";
import './LocationPage.scss';

import Carousel1 from '../images/carousel-1.png';
import Carousel2 from '../images/carousel-2.png';
import Carousel3 from '../images/carousel-3.png';

const CarouselImage = ({ imgSrc }) => (
    <div className='carousel-image'>
        <Image imgSrc={imgSrc} alt={''} />
    </div>
);

const LocationPage = () => (
    <React.Fragment>
        <Row>
            <Col xs={{span: 24, offset: 0}} lg={{span: 20, offset: 2}}>
                <Carousel autoplay>
                    <CarouselImage imgSrc={Carousel1} />
                    <CarouselImage imgSrc={Carousel2} />
                    <CarouselImage imgSrc={Carousel3} />
                </Carousel>
            </Col>
        </Row>
        <Section>
            <Row>
                <Col lg={{span: 12, offset: 0}} xs={{span: 24, offset: 0}}>
                    <Section>
                        <SectionTitle>Location &amp; Contact</SectionTitle>
                        <Divider />
                        <Centered>
                            <InlineText><b>Office Location</b></InlineText>
                        </Centered>
                        <Centered>
                            <InlineText>{ADDRESS_STREET}</InlineText>
                        </Centered>
                        <Centered>
                            <InlineText>{ADDRESS_SUITE}</InlineText>
                        </Centered>
                        <Centered>
                            <InlineText>{ADDRESS_CITY_STATE}</InlineText>
                        </Centered>
                        <Centered>
                            <InlineText><b>Phone</b>: {PHONE}</InlineText>
                        </Centered>
                        <Centered>
                            <InlineText><b>Fax</b>: {FAX}</InlineText>
                        </Centered>
                        <Centered>
                            <InlineText>
                                <b>Email</b>: <InlineLink href={MAILTO_LINK}>{EMAIL}</InlineLink>
                            </InlineText>
                        </Centered>
                    </Section>
                </Col>
                <Col lg={{span: 12}} xs={{span: 24}}>
                    <div className='google-map-wrapper'>
                        <GoogleMap />
                    </div>
                </Col>
            </Row>
        </Section>
    </React.Fragment>
);

export default LocationPage;