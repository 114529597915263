import throatClearingPdf from '../assets/AddressingChronicThroatClearing.pdf'
import colonCancerPdf from '../assets/AddressingChronicThroatClearing.pdf';
import depressionPdf from '../assets/Depression_NotCelebrityTrend.pdf';
import constipationPdf from '../assets/BottomOfConstipation.pdf';
import headacheBasicsPdf from '../assets/HeadacheBasics.pdf';
import heartDiseasePdf from '../assets/HeartDiseaseUpdate_Awareness.pdf';
import domesticAbusePdf from '../assets/DomesticViolence_TheVictimsAroundUs.pdf';
import octoberCovidEmailPdf from '../assets/OctoberCovidEmail.pdf';
import osteoporosisPdf from '../assets/Osteoporosis_TheSilentCulprit.pdf';
import skinCancerPdf from '../assets/SkinCancer.pdf';
import stress2020 from '../assets/DealingWith2020Stress.pdf';
import conciergeRevolution from '../assets/TheConciergeRevolution.pdf';

export const Articles = {
    ADDRESSING_YOUR_CHRONIC_THROAT_CLEARING: 'addressing-your-chronic-throat-clearing',
    COLON_CANCER_PREVENTION_MY_EXPERIENCE: 'colon-cancer-prevention-my-experience',
    DEPRESSION_ITS_NOT_JUST_A_CELEBRITY_TREND: 'depression-its-not-just-a-celebrity-trend',
    GETTING_TO_THE_BOTTOM_OF_CONSTIPATION: 'getting-to-the-bottom-of-constipation',
    HEADACHE_BASICS: 'headache-basics',
    HEART_DISEASE_UPDATE_THE_IMPORTANCE_OF_AWARENESS: 'heart-disease-update-the-importance-of-awareness',
    LOOKING_OUT_FOR_DOMESTIC_VIOLENCE: 'looking-out-for-domestic-violence',
    OCTOBER_COVID_EMAIL: 'october-covid-email',
    OSTEOPOROSIS_A_SILENT_CULPRIT: 'osteoporosis-a-silent-culprit',
    SKIN_CANCER_RISKS_IN_THE_FLORIDA_SUN: 'skin-cancer-risks-in-the-florida-sun',
    STRESS_2020: 'dealing-with-stress-from-2020',
    THE_CONCIERGE_REVOLUTION_PRIMARY_CARE_PERSPECTIVE: 'the-concierge-revolution-primary-care-perspective',
};

export const HighlightedArticles = [
    Articles.THE_CONCIERGE_REVOLUTION_PRIMARY_CARE_PERSPECTIVE,
    Articles.LOOKING_OUT_FOR_DOMESTIC_VIOLENCE,
];

export const ArticlesList = Object.values(Articles);
export const ArticleMetadata = {
    [Articles.ADDRESSING_YOUR_CHRONIC_THROAT_CLEARING]: {
        title: 'Addressing Your Chronic Throat Clearing',
        publication: 'Old Naples News',
        date: 'December 2017',
        docHref: throatClearingPdf,
    },
    [Articles.COLON_CANCER_PREVENTION_MY_EXPERIENCE]: {
        title: 'Colon Cancer Prevention: My Experience',
        publication: 'Old Naples News',
        date: 'February 2019',
        docHref: colonCancerPdf,
    },
    [Articles.DEPRESSION_ITS_NOT_JUST_A_CELEBRITY_TREND]: {
        title: "Depression: It's Not Just a Celebrity Trend",
        publication: 'Old Naples News',
        date: 'December 2018',
        docHref: depressionPdf,
    },
    [Articles.GETTING_TO_THE_BOTTOM_OF_CONSTIPATION]: {
        title: 'Getting to the Bottom of Constipation',
        publication: 'Old Naples News',
        date: 'August 2019',
        docHref: constipationPdf,
    },
    [Articles.HEADACHE_BASICS]: {
        title: 'Headache Basics',
        publication: 'Old Naples News',
        date: 'August 2020',
        docHref: headacheBasicsPdf,
    },
    [Articles.HEART_DISEASE_UPDATE_THE_IMPORTANCE_OF_AWARENESS]: {
        title: 'Heart Disease Update: The Importance of Awareness',
        publication: 'North Naples News',
        date: 'November 2019',
        docHref: heartDiseasePdf,
    },
    [Articles.LOOKING_OUT_FOR_DOMESTIC_VIOLENCE]: {
        title: 'Looking out for Domestic Violence: The Victims Around Us',
        publication: 'Old Naples News',
        date: 'February 2020',
        docHref: domesticAbusePdf,
    },
    [Articles.OCTOBER_COVID_EMAIL]: {
        title: 'October COVID-19 Email Update',
        publication: 'Email',
        date: 'October 2020',
        docHref: octoberCovidEmailPdf,
    },
    [Articles.OSTEOPOROSIS_A_SILENT_CULPRIT]: {
        title: 'Osteoporosis: A Silent Culprit',
        publication: 'Old Naples News',
        date: 'April 2019',
        docHref: osteoporosisPdf,
    },
    [Articles.SKIN_CANCER_RISKS_IN_THE_FLORIDA_SUN]: {
        title: 'Skin Cancer Risks in the Florida Sun',
        publication: 'Old Naples News',
        date: 'April 2020',
        docHref: skinCancerPdf,
    },
    [Articles.STRESS_2020]: {
        title: 'Dealing With The Stress of 2020',
        publication: 'Old Naples News',
        date: 'January 2021',
        docHref: stress2020,
    },
    [Articles.THE_CONCIERGE_REVOLUTION_PRIMARY_CARE_PERSPECTIVE]: {
        title: "The Concierge Revolution: A Primary Care Physician's Perspective",
        publication: 'Old Naples News',
        date: 'November 2017',
        docHref: conciergeRevolution,
    },
};


export const ArticleDetails = {

};

