import React from 'react';
import { Col, Divider, Row } from 'antd';
import { Link } from 'react-router-dom';
import { Centered } from "./common/Layout";
import BackgroundTitle from "./BackgroundTitle";
import { Section, SectionTitle, SectionBody, InlineLink, CTAButton } from "./common/Section";
import {
    BenefitsPage_ConciergeExplained1,
    BenefitsPage_ConciergeExplained2,
    BenefitsPage_ConciergeExplained3,
    BenefitsPage_BenefitsIncludedList,
    BenefitsPage_Joining1,
    BenefitsPage_Joining2,
} from "../data/Copy";
import { EMAIL, MAILTO_LINK } from "../data/Constants";
import BeachWalkingImg from '../images/beach-walking.jpg';
import './BenefitsPage.scss';

const BenefitsPage = () => (
    <React.Fragment>
        <BackgroundTitle
            imgSrc={BeachWalkingImg}
            title='Member Benefits'
        />
        <Section>
            <SectionTitle>Concierge Explained</SectionTitle>
            <Divider />
            <SectionBody>{BenefitsPage_ConciergeExplained1}</SectionBody>
            <SectionBody>{BenefitsPage_ConciergeExplained2}</SectionBody>
            <SectionBody>{BenefitsPage_ConciergeExplained3}</SectionBody>
            <Centered>
                <Link to='/articles'>
                    <CTAButton>Read Article</CTAButton>
                </Link>
            </Centered>
        </Section>
        <Section>
            <SectionTitle>Benefits Included</SectionTitle>
            <Divider />
            <Row>
                <Col xs={{span: 24, offset: 0}} lg={{span: 20, offset: 2}} >
                    <ul className='benefits-included-list'>
                        {BenefitsPage_BenefitsIncludedList.map(benefit => (
                            <li><SectionBody>{benefit}</SectionBody></li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </Section>
        <Section>
            <SectionTitle>Interested in Joining?</SectionTitle>
            <Divider />
            <SectionBody>
                {BenefitsPage_Joining1}
                <InlineLink href={MAILTO_LINK}>{EMAIL}</InlineLink>
            </SectionBody>
            <SectionBody>{BenefitsPage_Joining2}</SectionBody>
        </Section>
    </React.Fragment>
);

export default BenefitsPage;