import React from 'react';
import { Col, Row } from 'antd';

import './BackgroundTitle.scss';

const getImage = src => (
  'linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url('+src+')'
);

const BackgroundTitle = ({ imgSrc, title, subtitle }) => (
    <div className='background' style={{ backgroundImage: getImage(imgSrc) }}>
        <Row type='flex' justify='center' align='middle' className='title-wrapper'>
            <Col span={24}>
                <h1 className='title'>{title}</h1>
                <h2 className='subtitle'>{subtitle}</h2>
            </Col>
        </Row>
    </div>
);

export default BackgroundTitle;