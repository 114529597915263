import React from 'react';
import { Button, Col, Row, Typography } from "antd";
import { Centered } from "./Layout";

import './Section.scss';

export const Image = ({ imgSrc, alt, ...props}) => (
    <img className='img' src={imgSrc} alt={alt} {...props} />
);

export const TextLink = ({ children, ...props }) => (
    <span className='text-link' {...props}>{children}</span>
);

export const InlineText = ({ children, ...props }) => (
    <span className='inline-text' {...props}>{children}</span>
);

export const InlineLink = ({ children, ...props }) => (
    <a className='inline-link' {...props}>{children}</a>
);

export const CTAButton = ({ children, ...props }) => (
    <Button className='cta-button' size='large' {...props}>{children}</Button>
);

export const Section = ({ children }) => (
    <Row className='section'>
        <Col span={22} offset={1}>{children}</Col>
    </Row>
);

export const SectionTitle = ({ children }) => (
    <Centered>
        <Typography.Title level={1} className='section-title'>{children}</Typography.Title>
    </Centered>
);

export const SectionBody = ({ children }) => (
    <p className='section-body'>{children}</p>
);